@use 'colors' as *;
@use 'gradients' as *;
@use 'mixins' as *;
@use 'vars' as *;

button, .button {
  display: inline-block;
  position: relative;
  border-radius: $BUTTON_HEIGHT * 0.5;
  min-height: $BUTTON_HEIGHT;
  font-size: 14px;
  font-weight: 500;
  background-color: $COLOR_PRIMARY;
  border: none;
  @include a_tag_color(white);
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 300ms, border-color 300ms;

  &:not(.disabled) {
    &:hover {
      background-color: $COLOR_SECONDARY;
      border-color: $COLOR_SECONDARY;
    }
  }

  &.gray {
    background-color: $GRAY_MEDIUM;
    border-color: $GRAY_MEDIUM;
  }

  &.red {
    background-color: $SS_RED;
    border-color: $SS_RED;
  }

  &.green {
    background-color: $SS_GREEN;
    border-color: $SS_GREEN;
  }

  &.blue {
    background-color: $SS_BLUE;
    border-color: $SS_BLUE;
  }

  @mixin button_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: none;
  }

  kallo-loading-spinner {
    @include button_icon;
  }

  .icon {
    @include button_icon;
  }

  &.loading {
    span {
      color: transparent;
    }

    kallo-loading-spinner {
      display: block;
    }
  }
}
