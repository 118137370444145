//$HEADER_WIDTH: 1200px;
$HEADER_HEIGHT: 70px;
$HEADER_LOGO_HEIGHT: 32px;
$HEADER_LOGO_WIDTH: 200px;
$HEADER_AVATAR_HEIGHT: 30px;
$PRIMARY_WIDTH: 1000px;
$BUTTON_HEIGHT: 32px;

$PADDING_NORMAL: 20px;
$PADDING_SMALL: 8px;


$LEFT_NAV_CLOSED_WIDTH: $PADDING_NORMAL * 3;
$LEFT_NAV_OPEN_WIDTH: 250px;

$RIGHT_CHAT_OPEN_WIDTH: 400px;

$TRANSITION_STANDARD: var(--kallo-standard-transition);

$BORDER_RADIUS_DEFAULT: 16px;
$BORDER_RADIUS_REDUCED: 11px;
$BORDER_RADIUS_SMALL: 6px;
$BORDER_RADIUS_LARGER: $BORDER_RADIUS_DEFAULT * 1.5;
$BORDER_RADIUS_HUGE: $BORDER_RADIUS_DEFAULT * 2.5;

$CONTENT_SECTION_MARGIN: $BORDER_RADIUS_HUGE;

$RESPONSIVE_SCREEN_WIDTH: 450px;

$MENU_ITEM_HEIGHT: 40px; // changing this necessitates a change in the cdk-virtual-scroll-viewport element attributes
$GALLERY_ITEM_WIDTH: 300px;

$Z_INDEX_MENU: 1010;
