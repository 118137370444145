$GREEN_LIGHT: #E9FFEA;
$GREEN: #8dff95;
$ORANGE: #FFAC12;
$RED: #f26666;
$RED_LIGHT: #ffc2c2;
$SKY: #31e4fa;
$EGGPLANT: #4B4E6D;

$BLUE: #31b5ff;
$BLUE_LIGHT: #b6e9ff;
$BLUE_TINT: #339ad3;

$INDIGO: #4877FF;

$FUCHSIA: #d775ff;
$FUCHSIA_TINT: #ad65cb;


$GRAY_BLUE_LIGHT: #4a556d;
$GRAY_BLUE: #2B303B;
$GRAY_BLUE2: #272b35;
$GRAY_BLUE3: #232730;

$GRAY_FAINT: #f5f5f5;
$GRAY_LIGHT_FAINT: #eaeaea;
$GRAY_LIGHT: #ccc;
$GRAY_MEDIUM_LIGHT: #aaa;
$GRAY_MEDIUM: #888;
$GRAY_MEDIUM_DARK: #4b4b4b;

$PURPLE_DARK: #140f28;

$SS_BLUE: #4877ff;
$SS_BLUE_FAINT: rgba(71, 117, 255, 0.08);
$SS_GREEN: #61b958;
$SS_GREEN_FAINT: rgba(99, 186, 89, 0.10);
$SS_GOLD: #ff8928;
$SS_GOLD_FAINT: rgba(255, 137, 41, 0.15);
$SS_RED: #f26666;
$SS_RED_FAINT: rgba(242, 102, 102, 0.15);
$SS_PURPLE: #4b2fc9;
$SS_PURPLE_FAINT: rgba(96, 47, 201, 0.10);
$SS_LIME: #bdd565;
$SS_LIME_FAINT: rgba(190, 214, 101, 0.13);
$SS_LIME_DARK: #829331;

$COLOR_PRIMARY: $SS_BLUE;
$COLOR_SECONDARY: $SS_GREEN;
$COLOR_TERTIARY: $SS_GOLD;
