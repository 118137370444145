@forward "buttons";
@forward "colors";
@forward "crumbs";
@forward "fonts";
@forward "gradients";
@forward "info-bar";
@forward "menu";
@forward "mixins";
@forward "mobile";
@forward "pill";
@forward "shadows";
@forward "vars";

@use "colors" as *;
@use "fonts" as *;
@use "vars" as *;

:host {
  font-family: $FONT_DEFAULT;
  position: relative;
}

html, body {
  margin: 0;
  padding: 0;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
  background-color: $PURPLE_DARK;
}

* {
  font-family: $FONT_DEFAULT;
  letter-spacing: -0.2px;
}

h1, .h1 {
  font-size: 36px;
  font-weight: 500;
  color: $GRAY_BLUE;
}

h2, .h2 {
  font-size: 27px;
  margin-top: 18px;
  margin-bottom: 18px;
  line-height: 35px;
  font-weight: 500;
  color: $GRAY_BLUE;
}

h1, h2, h3, h4, h5, p {
  &.blue {
    color: $SS_BLUE;
  }

  &.green {
    color: $SS_GREEN;
  }

  &.lime {
    color: $SS_LIME;
  }

  &.gold {
    color: $SS_GOLD;
  }

  &.red {
    color: $SS_RED;
  }

  &.gray {
    color: $GRAY_MEDIUM;
  }
}

h3, .h3, h4, .h4 {
  font-weight: 400;
  color: $GRAY_BLUE;
}

p, ul {
  font-size: 14px;
  font-weight: 300;
  color: $GRAY_BLUE;
}

ul {
  margin-left: -30px;

  li {
    padding-left: 3px;
  }
}

a, a:visited, a:hover, a:active {
  text-decoration: none;
  color: $COLOR_PRIMARY;
}

pre {
  font-size: 10px;
  font-family: Monaco, "Lucida Console", monospace;
  white-space: pre-wrap;
}

div {
  box-sizing: border-box;
}

input:not([type=checkbox]), select, textarea {
  -webkit-appearance: none;
}

input {
  border-radius: $BORDER_RADIUS_REDUCED;
  padding: 10px;
  border: 1px solid $GRAY_MEDIUM_DARK;
  box-sizing: border-box;
  font-size: 16px;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}

::-webkit-scrollbar {
  height: 0;
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

