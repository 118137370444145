@use 'colors' as *;
@use 'mobile' as *;
@use 'vars' as *;

@mixin a_tag_color($color) {
  color: $color;

  &a:visited, &a:hover, &a:active {
    color: $color !important;
  }
}

// For components that are placed directly in the primary .content space,
// this is intended to be @include-ed in the :host rule
@mixin content_host() {
  margin: 0 0 $CONTENT_SECTION_MARGIN 0;

  h2 {
    margin-top: $CONTENT_SECTION_MARGIN;
  }
}

// This will cause content to fit within a set width while also giving
// flexibility to a component to set, for example, 100%-width background
// colors and do other things that require full control of 100% of the
// horizontal space. It should usually work inside :host but occasionally
// may be more suited for some child element of a :host
@mixin content_child_container() {
  $MIN_HORIZ_PAD: 25px;

  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0 $MIN_HORIZ_PAD;

  @media (min-width: $PRIMARY_WIDTH) {
    padding-left: calc(50% - $PRIMARY_WIDTH / 2 + $MIN_HORIZ_PAD);
    padding-right: calc(50% - $PRIMARY_WIDTH / 2 + $MIN_HORIZ_PAD);
  }
}

// For content that appears when user is not logged in; a splash image
// appears on the left and interactive content on the right
@mixin not_authed_host() {
  display: flex;
  height: 100vh;
  max-height: -webkit-fill-available;
  //margin-left: calc(100% - $RESPONSIVE_SCREEN_WIDTH);

  @include media-mobile() {
    margin: 0;
  }
}

@mixin not_authed_content() {
  flex-basis: $RESPONSIVE_SCREEN_WIDTH;
  padding: $PADDING_NORMAL;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

/**
 * Place this inside the outermost div that is the floating
 * box
 */
@mixin floating_content_box($shadow: false) {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  border: 1px solid $GRAY_FAINT;
  //padding: $PADDING_NORMAL;
  border-radius: $BORDER_RADIUS_DEFAULT;
  overflow: hidden;

  @if ($shadow == true) {
    border-color: transparent;
    box-shadow: $BOX_SHADOW_FAINT;
  }
}
